import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarCategoria = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_CATEGORIA + `?page=${page}`, payload);
    return data
}

export const criarCategoria = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_CATEGORIA, payload);
    return data
}

export const editarCategoria = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_CATEGORIA + `/${id}`, payload);
    return data
}

export const exibirCategoria = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_CATEGORIA + `/${id}`);
    return data
}

export const excluirCategoria = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_CATEGORIA + `/${id}`);
    return data
}

export const listarCategoria = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_CATEGORIA, payload);
    return data
}

export const todasCategorias = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.TODAS_CATEGORIAS, payload);
    return data
}