import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarProduto = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_PRODUTO + `?page=${page}`, payload);
    return data
}

export const criarProduto = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_PRODUTO, payload);
    return data
}

export const exibirProduto = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_PRODUTO  + `/${id}`);
    return data
}

export const editarProduto = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_PRODUTO  + `/${id}`, payload);
    return data
}

export const excluirProduto = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_PRODUTO + `/${id}`);
    return data
}

export const listarProdutos = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_PRODUTO, payload);
    return data
}