import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarTag = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_TAG + `?page=${page}`, payload);
    return data
}

export const criarTag = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_TAG, payload);
    return data
}

export const editarTag = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_TAG + `/${id}`, payload);
    return data
}

export const exibirTag = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_TAG + `/${id}`);
    return data
}

export const excluirTag = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_TAG + `/${id}`);
    return data
}

export const listarTags = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_TAG, payload);
    return data
}