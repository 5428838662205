import { apiInstance, apiInstanceFormData } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarArtigos = async (page, payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_ARTIGO + `?page=${page}`, payload);
    return data
}

export const criarArtigo = async (payload) => {
    const { data } = await apiInstanceFormData.post(ENDPOINTS.CRIAR_ARTIGO, payload);
    return data
}

export const editarArtigo = async (id, payload) => {
    const { data } = await apiInstanceFormData.post(ENDPOINTS.EDITAR_ARTIGO + `/${id}`, payload);
    return data
}

export const exibirArtigo = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_ARTIGO + `/${id}`);
    return data
}

export const excluirArtigo = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_ARTIGO + `/${id}`);
    return data
}

export const listarArtigos = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_ARTIGO, payload);
    return data
}

export const obterLinkArtigo = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.OBTER_LINK_ARTIGO + `/${id}`);
    return data
}