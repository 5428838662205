import { render, staticRenderFns } from "./ModelosFormulario.vue?vue&type=template&id=493db06b&scoped=true"
import script from "./ModelosFormulario.vue?vue&type=script&lang=js"
export * from "./ModelosFormulario.vue?vue&type=script&lang=js"
import style0 from "./ModelosFormulario.vue?vue&type=style&index=0&id=493db06b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493db06b",
  null
  
)

export default component.exports