import { apiInstance,  } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarModelos = async (payload, page) => {
  const { data } = await apiInstance.post(
    ENDPOINTS.BUSCAR_MODELO + `?page=${page}`,
    payload
  );
  return data;
};

export const criarModelo = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.CRIAR_MODELO, payload);
  return data;
};

export const duplicarModelo = async (id) => {
  const { data } = await apiInstance.get(ENDPOINTS.DUPLICAR_MODELO + `/${id}`);
  return data;
};

export const editarModelo = async (id, payload) => {
  const { data } = await apiInstance.put(ENDPOINTS.EDITAR_MODELO + `/${id}`, payload);
  return data;
};

export const exibirModelo = async (id) => {
  const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_MODELO + `/${id}`);
  return data;
};

export const excluirModelo = async (id) => {
  const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_MODELO + `/${id}`);
  return data;
};

export const listarModelo = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.LISTAR_MODELO, payload);
  return data;
};

export const visualizarPDFModelo = async (id) => {
  const { data } = await apiInstance.put(ENDPOINTS.PREVIEW_PDf_MODELO + `/${id}`);
  return data;
};